import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import { Section, Button, Title, Text, Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Contact from "../sections/common/Contact"
import imgWorkCover from "../assets/image/jpg/agency/masonry/transportation-shop.jpg"

import { device } from "../utils"

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className='px-sm-5'
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt='' className='img-fluid w-100' />
        </Container>
        <Section className='mt-lg-5'>
          <Container>
            <Row>
              <Col lg='8'>
                <Text variant='tag'>WEB DEVELOPMENT</Text>
                <Title variant='secSm' className='my-4'>
                  Transportation Airport-Hotel-Airport Web Application.{" "}
                </Title>
                <Text
                  variant='p'
                  css={`
                    max-width: 750px;
                  `}
                >
                  This is a web application that I created for Cabo Travel
                  Solutions company in Cabo San Lucas, Mexico in 2022. This web
                  application that I created is functional and easy to use on
                  desktop and mobile devices. It helped to Cabo Travel Solutions
                  company to increase their reservations for shuttle and private
                  transportations. I am proud of this web application.
                </Text>
              </Col>
              <Col lg='4'>
                <Link
                  to='https://www.reserve-cabotravelsolutions.com/transportations'
                  target='_blank'
                >
                  {" "}
                  <Button arrowRight>Live work</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Section>

        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
